
import { mapGetters, mapState } from 'vuex'
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
import initSocket from '~/mixins/initSocket'
export default {
    name: 'RegisterModal',
    mixins: [fingerprint, ReCaptcha, initSocket],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            acceptCondition: true,
            form: {
                phone: '',
                password: '',
                reg_fingerprint: '',
                language: 'th-TH',
                country_code: '',
            },
            showPassword: false,
            loading: false,
            suspendRegistrationLoading: false,
            currencyIsSuspended: false,
            reg_flow: 1, // Definitions: 1 = normal, 2 or 3 = no bank & account, 4 no everything about bank, 5 only crypto
            currency: [],
            isConditional: false,
            playerRegistrationType: 1,
            bankNameType: 1,
            bankOptionSelection: 1,
            firstChar: false,
            playerIdLength: false,
            underscore: false,
            formatStr: false,
            isShow: false,
            globalReg: null,
        }
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        phoneCountryCode() {
            return this.form.country_code === 'GO'
                ? this.globalReg
                : this.form.country_code
        },
        ...mapState({
            supportCountryAndLanguage: (state) =>
                state.settings.supportCountryAndLanguage,
            operationSetting: (state) => state.settings.operationSetting,
        }),
        ...mapGetters({
            banks: 'banks/banks',
            supportCountries: 'theme2/settings/supportCountry',
        }),
        formRule() {
            return {
                password: [
                    (v) => !!v || this.tl('passwordIsRequired'),
                    (v) =>
                        (v && v.length >= 6) || this.tl('passwordGreaterThan'),
                ],
                currency: [(v) => !!v || `${this.tl('currencyIsRequired')}`],
                country: [(v) => !!v || `${this.tl('countryIsRequired')}`],
                phone: [(v) => !!v || `${this.tl('phoneNumberIsRequired')}`],
                bank_id: [(v) => !!v || `${this.tl('bankIsRequired')}`],
                bank_account_number: [
                    (v) => !!v || `${this.tl('accountNumberIsRequired')}`,
                    (v) =>
                        /^[\d\s]+$/.test(v) ||
                        `${this.tl('accountNumberShouldContainOnlyNumber')}`,
                ],
                player_id: [(v) => !!v || `${this.tl('playerIdIsRequired')}`],
                account_holder_name: [
                    (v) => !!v || `${this.tl('accountHolderNameIsRequired')}`,
                ],
                bank_name: [(v) => !!v || `${this.tl('bankNameIsRequired')}`],
                agree: [(v) => !!v || ``],
            }
        },
    },
    watch: {
        acceptCondition(value) {
            if (value) {
                this.isConditional = false
            } else {
                this.isConditional = true
            }
        },
        operationSetting(value) {
            this.reg_flow = value.reg_flow
            this.playerRegistrationType = value.player_reg_id
            this.bankNameType = value.bank_name_type
        },
        bankOptionSelection(value) {
            if (value === 2) {
                this.form.bank_currency = this.$cookie.get('currency')
            }
        },
    },
    created() {
        if (this.supportCountries.length) {
            this.country = this.supportCountries[0]?.country_code
            this.form.currency = this.supportCountries[0]?.default_currency
        }
    },
    async mounted() {
        const countryCode = this.$cookie.get('country_code')
        await this.$store.dispatch('banks/banks')
        await this.onOperationSetting(this.$cookie.get('currency'))
        this.form.reg_fingerprint = await this.visitorId()
        this.form = {
            ...this.form,
            country_code: countryCode,
        }
        this.countryChange(countryCode)
        const globalReg = this.$cookie?.get('global_reg')
        this.globalReg = globalReg
    },
    methods: {
        async onOperationSetting(currency) {
            await this.$store.dispatch('settings/operationSetting', currency)
        },
        onCloseModal() {
            this.modal = false
            this.$store.commit('theme2/settings/setDisplayRegisterModal', false)
            this.$emit('onCloseModal')
            this.$emit('close')
        },
        async register() {
            if (this.acceptCondition) {
                if (this.$refs.form.validate()) {
                    try {
                        this.loading = true
                        this.form.recaptchaToken =
                            await this.getRecaptchaToken()

                        this.form.reg_flow = this.reg_flow
                        if (this.playerRegistrationType !== 1) {
                            delete this.form.player_id
                        }
                        const res = await this.$store.dispatch(
                            'auth/registration',
                            this.form
                        )
                        await this.$store.dispatch(
                            'settings/supportCountryAndLanguage',
                            { fetch: true }
                        )
                        if (res) {
                            this.modal = false
                            this.$emit('onCloseModal')
                            this.$store.commit(
                                'theme2/settings/setDisplayRegisterModal',
                                false
                            )
                            // Delay WebSocket initialization to ensure previous operations are fully completed
                            setTimeout(async () => {
                                await this.initSocketTheme()
                            }, 1000)
                            this.$router.push('/player/deposits/apply')
                        }
                        this.loading = false
                    } catch (error) {
                        this.loading = false
                    }
                }
            } else {
                this.isConditional = true
                this.$toast.error(this.tl('pleaseCheckCheckCondition'))
            }
        },

        inputPlayerId(e) {
            const step1Regex = /^[a-z]/
            const step3Regex = /^[a-z][a-z0-9_]{3,10}[a-z0-9]$/
            if (e?.length >= 1) this.isShow = true
            else this.isShow = false
            if (step1Regex.test(e.charAt(0))) this.firstChar = true
            else this.firstChar = false
            if (e?.length > 1 && e.includes('_')) this.underscore = true
            else this.underscore = false

            this.playerIdLength = e?.length >= 5 && e?.length <= 12

            const isValidStep3 = step3Regex.test(e)
            if (isValidStep3) this.formatStr = true
            else this.formatStr = false
        },
        countryChange(e) {
            this.onChangeLanguageString(e)

            if (e === 'GO') {
                this.$store.dispatch('settings/getDefaultCurrency').then(() => {
                    const globalReg = this.$cookie?.get('global_reg')

                    this.globalReg = globalReg
                })
            }

            this.$store.dispatch('banks/banks', { country_code: e })

            const selectedCountry = this.supportCountryAndLanguage.find(
                (el) => el.country_code === e
            )

            if (selectedCountry) {
                const countryCurrency = selectedCountry.currency_code
                this.form.currency =
                    countryCurrency || this.$cookie.get('currency')
                this.form.bank_currency = countryCurrency

                // fire to check suspend and player registration status
                this.onChangeCurrency(countryCurrency)
            }
        },
        async onChangeLanguageString(code) {
            if (
                this.$cookie?.get('reg_loading') &&
                this.$cookie?.get('country_code') !== code
            ) {
                this.$store.commit('settings/changeLanguageLoading', true)
            } else {
                this.$cookie.set('reg_loading', true)
            }
            const filter = this.supportCountryAndLanguage.find(
                (el) => el.country_code === code
            )
            if (filter?.currency_code) {
                if (this.$cookie?.get('currency') !== filter?.currency_code) {
                    this.$cookie.set('currency', filter?.currency_code)
                }
            }

            if (this.$cookie?.get('country_code') !== code) {
                this.$cookie.set('country_code', filter.country_code)
                this.$store.commit(
                    'settings/setDefaultCurrency',
                    filter.currency_code
                )
                await this.globalBrowserDefaultLanguage()
            }

            this.$store.commit('settings/changeLanguageLoading', false)
        },
        async onChangeCurrency(currency) {
            await this.onOperationSetting(currency)
            this.checkSuspendRegistration(currency)
        },
        async checkSuspendRegistration(currency) {
            this.suspendRegistrationLoading = true
            const response = await this.$store.dispatch(
                'theme2/player/checkSuspendRegistration',
                currency
            )

            if (response) {
                const { status } = response
                this.currencyIsSuspended = status
                if (status) {
                    this.$toast.error(this.tl('registrationIsNotAvailable'))
                }
            }
            this.suspendRegistrationLoading = false
        },
    },
}
